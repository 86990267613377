import React from "react";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      <footer
        id="rs-footer "
        className="rs-footer footer-main-home footer-modify8 "
      >
        
        <div className="footer-bottom">
          <div className="container footer-top p-0">
            <div className="bottom-border">
            <div className="row">
              <div className="col-lg-8 md-mb-20">
                <div className="copyright text-lg-start text-center text-white">
                  <p>
                    © <span>{year}</span> LikeME | All Rights Reserved.
                  
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pl-110 md-pl-15">
                <ul className="footer-social md-mb-30">
                  <li>
                    <a href="https://www.facebook.com/people/LikeMe-Coin/61557800742474" target="_blanck">
                    
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/likemecoin" target="_blanck">
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/LikeMeCoin" target="_blanck">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/likemecoin_en" target="_blanck">
                      <i className="fa fa-telegram" />
                      
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCZJNs8cQQR46h555wMEG4FQ" target="_blanck">
                      <i className="fa fa-youtube" />
                    </a>
                  </li>
                 
                </ul>
              </div>
            </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
