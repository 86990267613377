import React from "react";

export default function Marketing() {
  return (
    <div>
      <div className="rs-feature feature-style2 feature-modify4 pb-140 md-pt-70 md-pb-80">
        <div className="container">
          <div className="row y-middle text-start">
            <div className="col-lg-6 md-mb-50">
              <div className="feature-btn-wrap">
                <div className="sec-title mb-40">
                  <h2 className="title pb-25">
                    Benefits of Investing  <br /><span className="new-col"> in LikeME!</span>
                  </h2>
                  <p className="desc pb-30">
                    LikeME presents an astonishing opportunity for investors who
                    wish to achieve triple digit returns with short-term
                    strategies. It give access to multiple incomes
                  </p>
                  <ul className="check-lists check-style6">
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">Daily Income</span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">Referral Income</span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text"> Level Income</span>
                    </li>
                    <li className="list-item">
                      <span className="icon-list-icon">
                        <i className="fa fa-check-circle" />
                      </span>
                      <span className="list-text">Rank Bonuses & More!</span>
                    </li>
                  </ul>
                </div>
                <ul className="bnr-wrap">
                  <li>
                    <a className="readon started contact" href="#">
                      <span className="btn-text">Join now </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="feature-img">
                <img
                  className="js-tilt"
                  src="assets/img/benefits_of_Investing_in_likeme.png"
                  alt=" Benefits of Investing in LikeME!"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
