import React from "react";

export default function Main() {
  return (
    <div>
      {/* <div className="rs-videos video-style1 video-modify1  pt-130 pb-130 md-pt-80 md-pb-80">

        <div className="">
          <div className="sec-title text-center mb-55 md-mb-35">
            <h2 className="title pb-16 black-color">Invest in the FUTURE!</h2>
            <p className="desc  text-black">
            Social media platforms are crucial for and they will only upscale in coming years. <br /> LikeME’s goal is to stay at the forefront in this race! 
              
            </p>
          </div>
          <img
            src="assets/img/invest-in-the.jpg"
            alt=""
            style={{ width: "100%" }}
          />
        </div>
      </div> */}
      {/* <div className="rs-cta cta-style1 cta-modify3 bg6 pt-130 pb-130 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-38">
            <h2 className="title  center-style2 text-white">
              Invest in the <span className="new-col"></span> FUTURE!
            </h2>
            <p className="desc desc5 pt-25">
              Social media platforms are crucial for and they will only upscale
              in coming years. <br /> LikeME’s goal is to stay at the forefront
              in this race!
            </p>
          </div>
        </div>
      </div> */}
      <div className="rs-videos video-style1 bg2 pt-80 pb-130 md-pb-80">				
  <div className="container">
    <div className="sec-title text-center mb-55 md-mb-35">
      {/* <span className="sub-text">
        Collaboration
      </span> */}
      <h2 className="title">
      Invest in the <span className="new-col"> FUTURE!</span> 
      </h2>
      <p class="desc  pt-4">Social media platforms are crucial for and they will only upscale in coming years. <br />
      LikeME’s goal is to stay at the forefront in this race!</p>
    </div>
    <div className="video-item video-wrap">
      <div className="overly-border">
        <a className="popup-border popup-videos" href="https://www.youtube.com/watch?v=pLopcBOZggU" target="_blank">
          <i className="fa fa-play" />
        </a>
      </div>
    </div>
  </div>
  <div className="video-animation">
    <div className="video-animate left">
      <img className="scale2" src="assets/images/video/shape-left.png" alt="Images" />
    </div>
    <div className="video-animate right">
      <img className="scale2" src="assets/images/video/shape-right.png" alt="Images" />
    </div>
  </div>
</div>

      
    </div>
  );
}
