import React from "react";

import Slider from "react-slick";

export default function Program() {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    // speed: 3000,
    // autoplaySpeed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ backgroundColor: "#3a3a3a " }} className="pb-80">
      <div className="rs-team team-style1 pt-100 md-pt-80">
        <div className="container">
          <div className="sec-title text-center mb-30">
            <h2 className="title text-white">
              Roles at <span className="new-col"> LikeME</span>{" "}
            </h2>
          </div>
          <div className="slider team-slide-1">
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <div className="team-item-wrap">
                    <div className="team-inner-wrap">
                      <div className="image-wrap">
                        <a>
                          <img src="assets/img/associate.jpg" alt="Associate" />
                        </a>
                      </div>
                      <div className="team-content">
                        <h3 className="team-name">
                          <a>Associate </a>
                        </h3>
                        <span className="team-title">
                          Daily Rewards: 0.20%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-item-wrap">
                    <div className="team-inner-wrap">
                      <div className="image-wrap">
                        <a>
                          <img
                            src="assets/img/specialist.jpg"
                            alt="Specialist"
                          />
                        </a>
                      </div>
                      <div className="team-content">
                        <h3 className="team-name">
                          <a>Specialist</a>
                        </h3>
                        <span className="team-title">
                          Daily Rewards: 0.30%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-item-wrap">
                    <div className="team-inner-wrap">
                      <div className="image-wrap">
                        <a>
                          <img src="assets/img/manager.jpg" alt="Manager" />
                        </a>
                      </div>
                      <div className="team-content">
                        <h3 className="team-name">
                          <a>Manager</a>
                        </h3>
                        <span className="team-title">Daily Rewards: 0.40%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-item-wrap">
                    <div className="team-inner-wrap">
                      <div className="image-wrap">
                        <a>
                          <img src="assets/img/director.jpg" alt="Director" />
                        </a>
                      </div>
                      <div className="team-content">
                        <h3 className="team-name">
                          <a>Director</a>
                        </h3>
                        <span className="team-title">Daily Rewards: 0.50%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="team-item-wrap">
                    <div className="team-inner-wrap">
                      <div className="image-wrap">
                        <a>
                          <img src="assets/img/executive.jpg" alt="Executive" />
                        </a>
                      </div>
                      <div className="team-content">
                        <h3 className="team-name">
                          <a>Executive</a>
                        </h3>
                        <span className="team-title">
                          Daily Rewards: 0.60%{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
