import React, { useEffect, useState } from "react";

export default function Header() {
  // const epoint = useLocation().pathname;

  // const [headerShow, setHeaderShow] = useState(false);
  // const toggleHeaderShow = () => {
  //   if (headerShow === "defult-home") {
  //     setHeaderShow("defult-home nav-expanded");
  //   } else {
  //     setHeaderShow("defult-home");
  //   }
  // };
  // useEffect(() => {
  //   document.body.className = headerShow;
  // }, [headerShow]);
  return (
    <div>
      <div className="full-width-header">
        {/*Header Start*/}
        <header
          id="rs-header"
          className="rs-header header-style1 header-modify7 py-3 d-flex align-items-center"
        >
          {/* Menu Start */}
          <div className="menu-area menu-sticky">
            <div className="container-fluid px-md-5 px-sm-1 px-1">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <a href="/">
                      <img
                        className="normal-logo"
                        src="like_logo.png"
                        alt="logo"
                      />
                      <img
                        className="sticky-logo"
                        src="like_logo.png"
                        alt="logo"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        {/* <ul className="nav-menu">
                          <li className=" current-menu-item">
                            <a href="#">Home</a>
                          </li>
                          <li className="">
                            <a href="#">About</a>
                          </li>
                          <li className="">
                            <a href="#">Pages</a>
                          </li>
                          <li className="">
                            <a href="#">Blog</a>
                          </li>
                          <li className="last-item ">
                            <a href="#">Contact</a>
                          </li>
                        </ul>{" "} */}
                      </nav>
                    </div>{" "}
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="btn-signin">
                       
                      </li>
                      <li className="btn-quote">
                        <a href="http://app.lmcrefer.com/" target="_blank">
                          <span className="btn-text">
                          Launch App
                          </span>
                        </a>
                      </li>
                      {/* <li className="nav-link">
                        <a
                          id="nav-expander"
                          className="nav-expander bar"
               
                        >
                          <div className="bar">
                            <span className="dot1" />
                            <span className="dot2" />
                            <span className="dot3" />
                          </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Menu End */}
          {/* Canvas Mobile Menu start */}
          <nav
            className="right_menu_togle mobile-navbar-menu"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close" 
               >
                <div className="line">
                  <span className="line1" />
                  <span className="line2" />
                </div>
              </a>
            </div>
            <ul className="nav-menu">
              <li className=" current-menu-item">
                <a href="index-2.html">Home</a>
              </li>
              <li className="">
                <a href="#">About</a>
              </li>
              <li className="">
                <a href="#">Pages</a>
              </li>
              <li className="">
                <a href="#">Blog</a>
              </li>
              <li className="last-item ">
                <a href="#">Contact</a>
              </li>
            </ul>{" "}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            {/* //.nav-menu */}
            <div className="canvas-contact">
              <ul>
                <li className="btn-signin">
                  <a className="signin-button" href="contact-1.html">
                    <span className="sign-text">
                      <i className="ri-user-3-line" /> Sign In{" "}
                    </span>
                  </a>
                </li>
                <li className="btn-quote">
                  <a href="contact-1.html">
                    <span className="btn-text">
                      <i className="ri-download-line" /> Download
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* Canvas Menu end */}
        </header>
        {/*Header End*/}
      </div>
    </div>
  );
}
