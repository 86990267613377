import React from "react";

export default function About() {
  return (
    <div>
      <div className="rs-feature feature-style2 feature-modify6 pt-140 pb-140 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row y-middle text-start">
            <div className="col-lg-6 md-mb-50 text-start">
              <div className="sec-title">
                <h2>
                  LMC – One Currency for{" "}
                  <span className="new-col"> Multiple Utilities</span>
                </h2>
                <p className="desc  text-black pb-20">
                  LMC is native currency for LikeME & it has numerous uses
                  including user rewards, points-buying, in-app payments,
                  subscriptions & more!
                </p>
                <p className="mb-1">
                  <span className="h6">Token Name :</span> LikeME Coin
                </p>
                <p className="mb-1">
                  <span className="h6">Ticker :</span> LMC
                </p>
                <p className="mb-1">
                  <span className="h6">Chain :</span> BNB Smart Chain
                </p>

                {/* <p className="mb-1" style={{overflowX:"auto",overflowY:"hidden"}}>
                  <span className="h6">Contract :</span>{" "}
                  <a
                    // target="_blanck"
                    href=" # "
                    style={{ color: "black", borderBottom: "1px solid black" }}
                  >
                    0x7e0e93cbb941c59e0e1139f4c3908e185ea19f97
                  </a>
                </p> */}
                <div className="btn-part mt-45">
                  <a
                    className="readon started contact"
                    target="_blank"
                    href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7E0e93CBB941C59e0e1139f4c3908e185eA19f97"
                  >
                    <span className="btn-text">Buy Now</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 pl-50 md-pl-15">
              <div className="social-icon-img text-right md-text-center">
                <div className="social-main">
                  <img
                    className="scale2"
                    src="assets/img/one_currency_for_multiple_utilities.png"
                    alt="LMC – One currency for multiple utilities"
                  />
                  <div className="border-big">
                    <img
                      src="assets/images/feature/style9/border-big.png"
                      alt=""
                    />
                  </div>
                  <div className="border-small">
                    <img
                      src="assets/images/feature/style9/border-small.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
