import React from "react";

export default function What() {
  return (
    <div>
      <div className="rs-about about-style1 about-modify1 pt-110 pb-130 md-pt-10 md-pb-0">
        <div className="container custom6">
          <div className="row y-middle text-start">
            <div className="col-lg-6 md-mb-50">
              <div className="about-img">
                <img
                  className="js-tilt"
                  src="assets/img/why_should_you_invest_in_likeme.png"
                  alt=" Why should you invest in LikeME?"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sec-title">
                <h2 className="title pb-25">
                  Why should you invest <span className="new-col"> in LikeME?</span>
                </h2>
                <p className="desc pb-15">
                  LikeMe is an innovative decentralized social media platform
                  designed to address the issues of data privacy, centralized
                  control, & transparency prevalent in traditional social media.
                </p>
                <p className="desc">
                  Investing in LikeME is like investing in domain hosting in the
                  early 2000’s. The decentralized social (DeSo) industry will
                  get bigger in upcoming years with the broad adoption of Web3.
                  Let’s invest in LikeME & lead it to become the biggest web3
                  social media app!
                </p>
                <div className="btn-part mt-45">
                  <a className="readon started contact" href="#">
                    <span className="btn-text">Invest Now</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
