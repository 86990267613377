import React from "react";
import Slider from "react-slick";


export default function NFT() {
  var settings = {
    dots: true,
    // infinite: true,
    centerMode: true,
    infinite: true,
    autoplay: true,
    // speed: 3000,
    // autoplaySpeed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,

        },
      },
    ],
  };

  return (
    <div>
      <div className="rs-apps-screenshots apps-style1 bg10 pt-50 pb-100 md-pt-40 md-pb-60">
        <div className="container">
          <div className="sec-title text-center mb-40 md-mb-20">
            {/* <span className="sub-text sub-text5">App screen</span> */}
            <h2 className="title white-color">
            LikeME
              <br />
              NFT  <span className="new-col">Collections</span>    
            </h2>
          </div>
          <div className="slider apps-slide-1">
          <Slider {...settings}>
            <div>
            <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/elite.png" alt="Images" />
              </div>
            </div>
            </div>
            <div>
           <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/super_user.png" alt="Images" />
              </div>
            </div>
           </div>
           <div>
           <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/exclusives.png" alt="Images" />
              </div>
            </div>
           </div>
           <div>
          <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/pro.png" alt="Images" />
              </div>
            </div>
          </div>
        <div>
        <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/influencers.png" alt="Images" />
              </div>
            </div>
        </div>
         
         <div>
         <div className="grid-figure">
              <div className="slide-image">
                <img src="assets/img/stars.png" alt="Images" />
              </div>
            </div>
         </div>
                          </Slider>

          </div>
          <div className="multi-images">
            <img
              className="scale2"
              src="assets/images/apps/circle.png"
              alt="Images"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
