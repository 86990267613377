import React from "react";

export default function Static() {
  return (
    <div>
      <div className="rs-counter counter-style1 counter-modify4">
        <div className="container">
          <div className="counter-inner-wrap pt-95 pb-95 md-pt-80 md-pb-80 mt-80">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                <div className="rs-counter-list">
                  <div className="count-text">
                    <div className="count-number new-col">
                      <span className="rs-count">1.5</span>
                      <span className="prefix">k+</span>
                    </div>
                    <span className="title">Community Members</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
                <div className="rs-counter-list">
                  <div className="count-text">
                    <div className="count-number new-col">
                      <span className="rs-count">1</span>
                      <span className="prefix">k+</span>
                    </div>
                    <span className="title">Active Users</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
                <div className="rs-counter-list">
                  <div className="count-text">
                    <div className="count-number new-col">
                      <span className="rs-count ">$100</span>
                      <span className="prefix">k</span>
                    </div>
                    <span className="title">$100k</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="rs-counter-list">
                  <div className="count-text">
                    <div className="count-number new-col">
                      <span className="rs-count">$5</span>
                      <span className="prefix">k+</span>
                    </div>
                    <span className="title">Weekly Rewards</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
