import React from "react";
import Header from "../Coman/Header";
import Hero from "./Hero";
import Main from "./Main";
import About from "./About";
import Program from "./Program";
import Static from "./Static";
import What from "./What";
import Marketing from "./Marketing";
import Footer from "../Coman/Footer";
import NFT from "./NFT";
import Incom from "./Incom";
import Community from "./Community";

export default function Index() {
  return (
    <div>
      <div class="main-content">
        <Header />
        <Hero />
        <Static />
        <About />
        <Program />
        {/* <Decks /> */}
        <Main />
        <NFT />
        <What />
        <Marketing />
        <Incom/>
        {/* <Community/> */}
        <Footer />
      </div>
    </div>
  );
}
