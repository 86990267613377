import React from "react";

export default function Incom() {
  return (
    <div>
      <div className="rs-services services-style2 services-modify3 black-bg2 pt-140 pb-140 md-pt-80 md-pb-80">
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-35">
            {/* <span className="sub-text sub-text3">features</span> */}
            <h2 className="title white-color">
              Unlock multiple incomes 
              <br />
           <span className="new-col">   with one investment</span>
            </h2>
          </div>
          <div className="d-flex justify-content-center gap-3 flex-wrap flex-sm-wrap flex-lg-nowrap">
            <div className="">
              <div className="services-item" style={{width:"200px"}}>
                <div className="services-wrap">
                  <div className="services-icon">
                    <img
                      src="assets/img/blast.png"
                      alt="Images"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Blast</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="services-item" style={{width:"200px"}}>
                <div className="services-wrap">
                  <div className="services-icon">
                    <img
                      src="assets/img/faster.png"
                      alt="Images"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Faster</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm-mb-30">
              <div className="services-item" style={{width:"200px"}}>
                <div className="services-wrap">
                  <div className="services-icon">
                    <img
                      src="assets/img/ancestry.png"
                      alt="Images"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Ancestry</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="services-item" style={{width:"200px"}}>
                <div className="services-wrap">
                  <div className="services-icon">
                    <img
                      src="assets/img/expression.png"
                      alt="Images"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Expression</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="services-item" style={{width:"200px"}}>
                <div className="services-wrap">
                  <div className="services-icon">
                    <img
                      src="assets/img/believe.png"
                      alt="Images"
                    />
                  </div>
                  <div className="services-content">
                    <h4 className="title">
                      <a>Believe</a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
