import { Routes, Route } from "react-router-dom";

import './App.css';
import Index from "./Components/Index/Index";

function App() {
  return (
    <div className="App">
   <Routes>
   <Route path="/" element={<Index />} />
   </Routes>

    </div>
  );
}

export default App;
