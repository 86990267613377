import React from "react";

export default function Hero() {
  return (
    <div>
      <div className="rs-banner banner-style10">
        <div className="container custom2">
          <div className="content-wrap">
            <h1 className="title">
              Let’s invest in the web3{" "}
              <span className=""> social landscape</span>
            </h1>
            <div className="description">
              <p>
                LikeME is presenting an enormous opportunity to join & invest in
                the early era of web3 social ecosystem
              </p>
              <ul className="bnr-bottom d-flex justify-content-center gap-3">
                <li>
                  {/* <a
                    className="readon apps-btn pink-btn play-white"
                    href="#"
                  >
                 
                    <span className="btn-text"> Join</span>
                  </a> */}
                </li>
                <li>
                  <a
                    className="readon apps-btn pink-btn "
                    href="https://app.lmcrefer.com/"
                    target="_blank"
                  >
                    <span className="btn-text"> Launch App</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="banner-animate">
          <div className="bnr-animation one">
            <img
              className="spiner"
              src="assets/images/banner/style10/shape1.png"
              alt=""
            />
          </div>
          <div className="bnr-animation two">
            <img
              className="spiner"
              src="assets/images/banner/style10/shape2.png"
              alt=""
            />
          </div>
          <div className="bnr-animation three">
            <img
              className="left-right2"
              src="assets/images/banner/style10/shape3.png"
              alt=""
            />
          </div>
          <div className="bnr-animation four">
            <img
              className="scale"
              src="assets/images/banner/style10/shape4.png"
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
